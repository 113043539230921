body {
    font-family: 'Lora', serif;
}

h1, h2, h3, h4, h5, h6, .box-text h1, .box-text-cell-inner h1 {
    font-family: 'Lora', sans-serif;
}

.incaps, .nav-tabs li a {
    font-family: 'Lora', sans-serif;
}

div.bottom .row {
    padding: 15px;
}
div.bottom ul li, div.bottom ul li a {
    color: #ffffff;
}
div.bottom ul li {
    display: inline;
}

div.bottom ul li:not(:last-child):after {
    content:' |';
}

.description {
    min-height: auto;
}

.margin-bottom-0 {
    margin-bottom: 0px;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-20 {
    margin-top: 20px;
}
.margin-top-30 {
    margin-top: 30px;
}
.no-margin-top {
    margin-top: 0px !important;
}
.padding-top-50 {
    padding-top: 50px;
}

.padding-bottom-0 {
    padding-bottom: 0px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}
.padding-bottom-70 {
    padding-bottom: 70px;
}
.padding-bottom-80 {
    padding-bottom: 80px;
}
.padding-bottom-100 {
    padding-bottom: 100px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-80 {
    padding-top: 80px;
}
.padding-top-120 {
    padding-top: 120px;
}
.padding-top-130 {
    padding-top: 130px;
}
.padding-top-150 {
    padding-top: 150px;
}
.padding-top-100 {
    padding-top: 100px;
}
.text-align-right {
    text-align: right;
}
.text-align-left {
    text-align: left;
}
.parallaxOffset {
    margin-top: 30px;
}

.productThumbPadding {
    padding: 15px 35px;
}
.border-bottom {
    border-bottom: 1px solid #eee;
}
.description {
    margin-right: 5px;
    margin-left: 5px;
    max-width: none;
}

.description h3 {
    font-size: 18px;
}

.verticalAlignMiddle {
    vertical-align: middle;
    display: table-cell;
    table-layout: fixed;
    width: 500px;
}
.italic {
    font-style: italic;
}
.pagination {
    margin: 0px;
}
.pagesInfoWrapper {
    position: relative;
    top: -50px;
    z-index: 105;
}
#pager2 {
    bottom: 55px;
}
.text-shadow {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8), 0 0 1px #FFFFFF;
}
/**
COLORS
**/

::selection {
    color: #fff;
    background: rgba(92,62,33,0.9) !important;
}

::-moz-selection {
    color: #fff;
    background: rgba(92,62,33,0.9) !important;
}

::-webkit-selection {
    color: #fff;
    background: rgba(92,62,33,0.9) !important;
}

.whiteText, .whiteText a {
    color: #fff;
}

.backgroundThemeLight, .navbar-tshop {
   background: rgba(92,62,33,0.9) 
}

.backgroundThemeLightNoTransparent {
    background: rgba(106,78,53,1);
}

.backgroundThemeDark, .promo-1, .navbar-top {
    background: rgba(44,25,11,0.9);
}

.backgroundWhite {
    background: #fff;
}

.dropdown-menu {
    border-top: solid rgba(92,62,33,0.9);
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus, .cartMenu:hover > a.dropdown-toggle,
.nav > li.active, .nav > li > a:hover, .nav > li > a:focus, .nav > li:hover > a {
    background: rgba(44,25,11,0.9);
}

h5.parallaxSubtitle {
    font-size: 16px;
}

#pager2 span.cycle-pager-active {
    background: rgba(92,62,33,0.9) !important;
}
#pager2 span, #pager2 span.cycle-pager-active {
    border: 2px solid rgba(44,25,11,0.9);
}

.pagination > li > a, .pagination > li > span {
    color: rgba(92,62,33,0.9);
}
.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    color: rgba(44,25,11,0.9);
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: rgba(106,78,53,1);
    border-color: rgba(44,25,11,0.9);
}
.cart-actions button, .cart-actions .wishlist {
    background: none repeat scroll 0 0 rgba(92,62,33,0.9);
    border: 2px solid rgba(44,25,11,0.9);
}

.cart-actions button:hover {
    border: 2px solid rgba(92,62,33,0.9);
    background: rgba(44,25,11,0.9);
    color: #fff;
}

.socialIcon a:hover {
    background: rgba(92,62,33,0.9);
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus, .nav-tabs > li:hover > a {
    background: rgba(92,62,33,0.9);
}
.nav-tabs {
    border-bottom: 3px solid rgba(92,62,33,0.9);
}
/** Product image hack **/
.main-image > div.sp-thumbs {
    display: none;
}

.categoryHeaderOffset {
        margin-top: 50px;
    }

.padding-bottom-30 {
    padding-bottom: 30px;
}
   
.relative {
    position: relative;
}

.zIndex2 {
    z-index: 2;
}

.btn-primary, .btn-primary, .product:hover .add-fav:hover, .product:hover .add-fav.active {
    background: rgba(92,62,33,0.9);
}

.btn-primary, .product:hover, .featuredImgLook2 .inner:hover {
    border-color: rgba(44,25,11,0.9);
}

.action-control a:hover, .action-control a.active {
    background: rgba(44,25,11,0.9);
}

.nav.tree .open-tree > a, .nav.tree .open-tree > a:hover, .nav.tree .open-tree > a:focus {
    color: rgba(44,25,11,0.9);
}

.nav.tree > li.active, .nav.tree > li > a:hover, .nav.tree > li > a:focus, .nav.tree > li:hover > a {
    color: rgba(44,25,11,0.9);
}

.panel-default > .panel-heading {
    border-top: 4px solid rgba(44,25,11,0.9);
}

.panel-title {
    color: rgba(44,25,11,0.9);
}

h1, h2, h3, h4, h5, h6 {
    color: rgba(44,25,11,0.9);
}

.btn-discover:hover, .btn-site:hover, .newsLatterBox .btn:hover {
    background: rgba(44,25,11,0.9);
}

/** ORDERS **/
.orderStep li {
    width: 25%;
}

.orderStep li a {
    background: #EFF0F2;
}

.orderStep li.active a {
    background: rgba(92,62,33,0.9);
}

.product-title {
    text-transform: none;
}

@media (max-width: 767px) { 
    .banner {
        margin-top: 89px !important;
    }
    .cat-intro-text p {
        font-size: 14px;
        text-transform: none;
    }
}
@media (min-width: 768px) and (max-width: 991px) { 
    .banner {
        margin-top: 30px !important;
    }
    .cat-intro-text p {
        font-size: 14px;
        text-transform: none;
    }
}
@media (min-width: 992px) and (max-width: 1199px) { 
    .banner {
        margin-top: 30px !important;
    }
    
    .box-text h1, .box-text-cell-inner h1 {
        font-size: 48px;
    }
    .cat-intro-text p {
        font-size: 16px;
        text-transform: none;
    }
}
@media (min-width: 1200px) { 
    .banner {
        margin-top: 30px;
    }
    
    .box-text h1, .box-text-cell-inner h1 {
        font-size: 48px;
    }
    .cat-intro-text p {
        font-size: 16px;
        text-transform: none;
    }
}